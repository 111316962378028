import React from "react";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import * as styles from "./HomepageHeader.module.scss";
import Markdown from "react-markdown";

const HomepageHeader = ({ title, intro, image }) => {
  const processedImage = getImage(image);

  return (
    <>
      <div className={styles.image}>
        <GatsbyImage
          className={styles.gatsbyClassName}
          objectFit="cover"
          imgClassName={styles.gatsbyImage}
          image={processedImage}
        />
      </div>
      <div className={styles.intro}>
        <h1>{title}</h1>
        <Markdown>{intro}</Markdown>
      </div>
    </>
  );
};

export default HomepageHeader;
