import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { getImage, GatsbyImage } from "gatsby-plugin-image";
import ReactTooltip from "react-tooltip";

import * as styles from "./ImageGrid.module.scss";

const ImageGrid = () => {
  const data = useStaticQuery(graphql`
    query GridQuery {
      allFile(
        sort: { fields: childMarkdownRemark___frontmatter___gridorder }
        filter: { sourceInstanceName: { eq: "posts" }, ext: { eq: ".md" } }
      ) {
        edges {
          node {
            id
            childMarkdownRemark {
              frontmatter {
                gridorder
                title
                gridImage {
                  childImageSharp {
                    gatsbyImageData(placeholder: BLURRED)
                  }
                }
              }
            }
          }
        }
      }
    }
  `);
  const posts = data?.allFile?.edges;

  return (
    <div className={styles.imageGrid}>
      <ReactTooltip
        textColor="#FF0000"
        backgroundColor="#ffffff"
        class={styles.tooltip}
      />
      {posts.map((post) => {
        const postContent = post.node.childMarkdownRemark.frontmatter;
        const postImage = getImage(postContent.gridImage);
        if (postContent.gridorder === 0) return false;

        return (
          <div className={styles.gridImageWrapper} data-tip={postContent.title}>
            <GatsbyImage className={styles.gridImage} image={postImage} />
          </div>
        );
      })}
    </div>
  );
};

export default ImageGrid;
