import * as React from "react";
import { graphql } from "gatsby";
import Homepage from "../components/Homepage/Homepage";
import NavBar from "../components/Homepage/NavBar/NavBar";
import Seo from "../components/SEO";

// markup
const IndexPage = ({ data }) => {
  const { frontmatter: homePageContent } = data?.file?.childMarkdownRemark;
  const posts = data?.allFile?.edges;
  const homePageData = {
    page: homePageContent,
    posts,
  };
  return (
    <>
      <Seo />
      <NavBar />
      <Homepage data={homePageData} />
    </>
  );
};

export const pageQuery = graphql`
  query HomepageQuery {
    allFile(
      sort: { fields: childMarkdownRemark___frontmatter___order }
      filter: {
        sourceInstanceName: { eq: "posts" }
        ext: { eq: ".md" }
        childMarkdownRemark: { frontmatter: { slug: { ne: null } } }
      }
    ) {
      edges {
        node {
          id
          childMarkdownRemark {
            frontmatter {
              title
              order
              gridorder
              title
              slug
              words
              content
              image {
                childImageSharp {
                  gatsbyImageData(placeholder: BLURRED)
                }
              }
              secondaryImage {
                childImageSharp {
                  gatsbyImageData(placeholder: BLURRED)
                }
              }
              gridImage {
                childImageSharp {
                  gatsbyImageData(placeholder: BLURRED)
                }
              }
            }
          }
        }
      }
    }
    # HomePage
    file(
      sourceInstanceName: { eq: "pages" }
      childMarkdownRemark: { frontmatter: { name: { eq: "Homepage" } } }
    ) {
      id
      childMarkdownRemark {
        frontmatter {
          name
          intro
          title
          image {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED)
            }
          }
          credits
          groupShotOne {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED)
            }
          }
          groupShotTwo {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED)
            }
          }
          groupShotThree {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED)
            }
          }
          groupShotFour {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED)
            }
          }
          pullQuoteOne
          pullQuoteTwo
          pullQuoteThree
          talentCredits
          otherCredits
        }
      }
    }
  }
`;

export default IndexPage;
