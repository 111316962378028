// extracted by mini-css-extract-plugin
export var singlePost = "PostSection-module--singlePost--2Pt89";
export var caption = "PostSection-module--caption--2pjKk";
export var singlePost1 = "PostSection-module--singlePost-1--37J5U";
export var singlePost2 = "PostSection-module--singlePost-2--38Um6";
export var singlePost3 = "PostSection-module--singlePost-3--1wVAA";
export var singlePost4 = "PostSection-module--singlePost-4--2zdYk";
export var singlePost5 = "PostSection-module--singlePost-5--1nAde";
export var singlePost6 = "PostSection-module--singlePost-6--1Ids4";
export var singlePostmason2 = "PostSection-module--singlePostmason2--1ItlO";
export var singlePost7 = "PostSection-module--singlePost-7--1JTgh";
export var singlePost8 = "PostSection-module--singlePost-8--2Iwr9";
export var singlePost9 = "PostSection-module--singlePost-9--2orgm";
export var singlePost10 = "PostSection-module--singlePost-10--3hN93";
export var singlePost11 = "PostSection-module--singlePost-11--1qBfh";
export var singlePost12 = "PostSection-module--singlePost-12--st-pO";
export var singlePost13 = "PostSection-module--singlePost-13--2Zq-c";
export var singlePost14 = "PostSection-module--singlePost-14--2P5Wf";
export var singlePost15 = "PostSection-module--singlePost-15--PkpIR";
export var singlePostcurtly2 = "PostSection-module--singlePostcurtly2--3RDLV";
export var singlePost16 = "PostSection-module--singlePost-16--xYQbr";
export var singlePost17 = "PostSection-module--singlePost-17--UDJbX";
export var singlePost18 = "PostSection-module--singlePost-18--1MnZ4";
export var singlePost19 = "PostSection-module--singlePost-19--7-2pR";
export var singlePost20 = "PostSection-module--singlePost-20--zW3fS";