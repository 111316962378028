// extracted by mini-css-extract-plugin
export var wrapper = "Homepage-module--wrapper--2Ai-N";
export var credits = "Homepage-module--credits--3KIqj";
export var martineButton = "Homepage-module--martineButton--WM88W";
export var spin = "Homepage-module--spin--1jqQ1";
export var groupShotOne = "Homepage-module--groupShotOne--3E_GT";
export var groupShotTwo = "Homepage-module--groupShotTwo--2oS4u";
export var pullQuoteOne = "Homepage-module--pullQuoteOne--UhqJ-";
export var pullQuoteTwo = "Homepage-module--pullQuoteTwo--T2_1n";
export var pullQuoteThree = "Homepage-module--pullQuoteThree--usTA6";
export var bottomSection = "Homepage-module--bottomSection--zVCCI";
export var groupShotThree = "Homepage-module--groupShotThree--36jtD";
export var groupShotFour = "Homepage-module--groupShotFour--2vvzC";
export var otherCredits = "Homepage-module--otherCredits--18dK3";
export var talentCredits = "Homepage-module--talentCredits--1k4BX";
export var buyMagazine = "Homepage-module--buyMagazine--2n0OT";