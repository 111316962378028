import React, { useState } from "react";
import clsx from "clsx";

import * as styles from "./Navbar.module.scss";

const NavBar = () => {
  const [isOpen, setisOpen] = useState(false);
  return (
    <div className={styles.header}>
      <div className={styles.headerBar} id="site-header">
        <button
          data-burger=""
          className={styles.burgerButton}
          aria-haspopup="true"
          title="Reveal menu"
          onClick={() => setisOpen(!isOpen)}
        >
          <svg viewBox="0 0 29 25" xmlns="http://www.w3.org/2000/svg">
            <g fill="#FFF" fillRule="evenodd">
              {isOpen ? (
                <g transform="translate(2 .216667)">
                  <path d="m3.83882581 24.0961725-3.18374828-3.1837483 20.50609667-20.50609667 3.1837483 3.18374828z"></path>
                  <path d="m.65507753 3.59007581 3.18374828-3.18374828 20.50609669 20.50609667-3.1837483 3.1837483z"></path>
                </g>
              ) : (
                <g>
                  <path d="m0 4.5025h29v-4.5025h-29z"></path>
                  <path d="m0 14.7191667h29v-4.5025h-29z"></path>
                  <path d="m0 24.9358333h29v-4.5025h-29z"></path>
                </g>
              )}
            </g>
          </svg>
        </button>
        <a href="https://theface.com/" className={styles.logo}>
          <img
            src="https://theface.com/assets/images/logo-animated.gif"
            alt=""
            srcSet="https://theface.com/assets/images/logo-animated@2x.gif 2x"
          />
        </a>
      </div>
      <nav
        className={clsx(styles.nav, isOpen && styles.visibleNav)}
        aria-hidden="true"
        aria-label="main-navigation"
      >
        <ul>
          <li>
            <a href="https://theface.com/style">Style</a>
          </li>
          <li>
            <a href="https://theface.com/music">Music</a>
          </li>
          <li>
            <a href="https://theface.com/culture">Culture</a>
          </li>
          <li>
            <a href="https://theface.com/society">Society</a>
          </li>
          <li>
            <a href="https://theface.com/life">Life</a>
          </li>
          <li>
            <a href="https://theface.com/video">Video</a>
          </li>
          <li>
            <a href="https://theface.com/audio">Audio</a>
          </li>
          <li>
            <a href="https://theface.com/buy-magazine">Magazine</a>
          </li>
          <li>
            <a href="https://theface.com/shop">Shop</a>
          </li>
        </ul>
      </nav>
    </div>
  );
};

export default NavBar;
