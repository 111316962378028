import { Link } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import clsx from "clsx";
import React from "react";
import * as styles from "./PostSection.module.scss";

const PostSectionTwo = ({ posts }) => {
  return (
    <>
      {posts.map((post, index) => {
        if (post?.order === 0) return false;
        const postContent = post.node.childMarkdownRemark.frontmatter;
        const postSlug = postContent.slug;
        const postImage = getImage(postContent.image);
        const secondaryImage = getImage(postContent.secondaryImage);

        if (index > 6 && index < 15) {
          return (
            <>
              <div
                className={clsx(
                  styles.singlePost,
                  styles[`singlePost${index + 1}`]
                )}
              >
                <Link to={postSlug}>
                  <GatsbyImage image={postImage} />
                  <h3 className={styles.caption}>{postContent.title}</h3>
                </Link>
              </div>

              {postSlug === "/curtly-thomas" && (
                <div
                  className={clsx(styles.singlePost, styles.singlePostcurtly2)}
                >
                  <GatsbyImage image={secondaryImage} />
                </div>
              )}
            </>
          );
        }

        return null;
      })}
    </>
  );
};

export default PostSectionTwo;
