import React from "react";
import HomepageHeader from "./HomepageHeader/HomepageHeader";
import Markdown from "react-markdown";

import * as styles from "./Homepage.module.scss";
import PostSectionOne from "./PostsSection/PostSectionOne";
import PostSectionTwo from "./PostsSection/PostSectionTwo";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import ImageGrid from "./ImageGrid/ImageGrid";
import martineButton from "../../images/martine-button.png";
import PostSectionThree from "./PostsSection/PostSectionThree";

const Homepage = ({ data }) => {
  return (
    <main className={styles.wrapper}>
      <title>Home Page</title>
      <HomepageHeader
        title={data?.page?.title}
        intro={data?.page?.intro}
        image={data?.page?.image}
      />
      <div className={styles.credits}>
        <Markdown>{data?.page?.credits}</Markdown>

        <a href="https://theface.com/style/martine-rose-nike-the-lost-lionesses-england-football-kit-fashion-designer-interview-vol-4-issue-7">
          <img
            src={martineButton}
            className={styles.martineButton}
            alt="Click here to read our interview with Martine"
          />
        </a>
      </div>

      <PostSectionOne posts={data.posts} />

      <GatsbyImage
        image={getImage(data?.page?.groupShotOne)}
        className={styles.groupShotOne}
      />

      <Markdown className={styles.pullQuoteOne}>
        {data?.page?.pullQuoteOne}
      </Markdown>

      <Markdown className={styles.pullQuoteTwo}>
        {data?.page?.pullQuoteTwo}
      </Markdown>

      <PostSectionTwo posts={data.posts} />

      <ImageGrid />

      <Markdown className={styles.pullQuoteThree}>
        {data?.page?.pullQuoteThree}
      </Markdown>

      <div className={styles.bottomSection}>
        <PostSectionThree posts={data.posts} />
        <div className={styles.groupShotTwo}>
          <GatsbyImage image={getImage(data?.page?.groupShotTwo)} />
        </div>
        <div className={styles.groupShotThree}>
          <GatsbyImage image={getImage(data?.page?.groupShotThree)} />
        </div>
      </div>

      <GatsbyImage
        image={getImage(data?.page?.groupShotFour)}
        className={styles.groupShotFour}
      />

      <div className={styles.talentCredits}>
        <Markdown>{data.page.talentCredits}</Markdown>
      </div>
      <div className={styles.otherCredits}>
        <Markdown>{data.page.otherCredits}</Markdown>
      </div>

      <div className={styles.buyMagazine}>
        <a href="https://theface.com/buy-magazine">Buy the magazine</a>
      </div>
    </main>
  );
};

export default Homepage;
